
a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.footer {
  height: 20px;
  margin-top: auto;
  background-color: #F5F4EF;
}

.card {
  margin: 2px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #F5F4EF;
}

.card-content {
  display: flex;
}

.file-content {
  display: flex;
}

.team-content {
  display: flex;
}